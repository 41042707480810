"use client";

import { useContext } from "react";
import { MerchantContext } from "@/contexts/MerchantContext";
import {
  type Breadcrumb,
  Breadcrumbs as CommonBreadcrumbs,
} from "../common/Breadcrumbs";

export const Breadcrumbs = () => {
  const { merchant } = useContext(MerchantContext);
  const merchantName = merchant?.retailer?.name || "";
  const currentPage: Breadcrumb | undefined = merchantName
    ? {
        displayText: merchantName,
      }
    : undefined;

  return <CommonBreadcrumbs currentPage={currentPage} sec="store-template" />;
};
