import { Icon } from "@yahoo/uds";
import { ChevronRight } from "@yahoo/uds-icons";
import { I13nAnchor, useRapid } from "@yahoo-commerce/i13n";
import classNames from "classnames";
import { usePathname } from "next/navigation";
import { type FC } from "react";
import { useRequestContext } from "@/lib/request/client";

export interface Breadcrumb {
  displayText: string;
  url?: string;
}

interface BreadcrumbsProps {
  currentPage?: Breadcrumb;
  sec: string;
}

const buildBreadcrumbs = ({
  homePage,
  pathname,
  currentPage,
}: {
  homePage: Breadcrumb;
  pathname: string;
  currentPage?: Breadcrumb;
}) => {
  const breadcrumbs: Breadcrumb[] = [homePage];
  const pathParts = pathname.split("/").filter(Boolean);
  const pathCrumbs = pathParts.map((part, index) => {
    // Build the url with the current part and all previous parts
    const url = `/${pathParts.slice(0, index + 1).join("/")}/`;
    return {
      displayText: part.replace(/-/g, " "),
      url,
    };
  });

  // If currentPage is included, do not add the last path crumb
  if (currentPage) {
    pathCrumbs.pop();
  }
  breadcrumbs.push(...pathCrumbs);

  if (currentPage) {
    breadcrumbs.push(currentPage);
  }

  return breadcrumbs;
};

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ currentPage, sec }) => {
  useRapid(sec);
  const { site } = useRequestContext();
  const pathname = usePathname();
  const homePage = {
    displayText: `Yahoo ${site}`, // capitalizing with CSS
    url: "/",
  };

  const breadcrumbs = buildBreadcrumbs({ currentPage, homePage, pathname });

  return (
    <ul
      id={sec}
      className="mx-auto flex w-full max-w-[1504px] items-center gap-2.5 px-4 pt-4 min-[640px]:px-6 min-[640px]:pt-6 lg:px-8 lg:pt-6"
    >
      {breadcrumbs.map((crumb, index) => {
        if (!crumb || !crumb.displayText) {
          return null;
        }

        const isLast = index === breadcrumbs.length - 1;

        return (
          <li
            key={index}
            className={classNames(
              "flex cursor-default items-center gap-2 text-sm capitalize",
              isLast && "font-weight-medium",
            )}
          >
            {crumb.url && !isLast ? (
              <I13nAnchor
                href={crumb.url}
                className={classNames(
                  "text-battleship underline hover:no-underline",
                  isLast && "cursor-default no-underline",
                )}
                dataYlk={{
                  elm: "navcat",
                  itc: 0,
                  sec,
                  slk: crumb.displayText,
                  subsec: "breadcrumb",
                }}
              >
                {crumb.displayText}
              </I13nAnchor>
            ) : (
              <span className="text-battleship">{crumb.displayText}</span>
            )}
            {!isLast && (
              <Icon
                size="sm"
                variant="fill"
                name={ChevronRight}
                className="inline-block"
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};
