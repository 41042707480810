"use client";

import {
  createContext,
  type PropsWithChildren,
  useContext,
  useState,
  type Dispatch,
  type SetStateAction,
  type FC,
} from "react";
import { useIntl, type IntlShape } from "react-intl";

import {
  type ItemCountBySourceType,
  PROMOTION_TYPE_V2,
  type MerchantContextType,
  type SearchDealsResponse,
} from "@/types/Accio";

export const MerchantContext = createContext<{
  merchant: MerchantContextType;
  setMerchant: Dispatch<SetStateAction<MerchantContextType>>;
}>({
  merchant: {
    bestDiscount: "",
    deals: {} as SearchDealsResponse,
    offerTypeAggregation: {} as ItemCountBySourceType,
    retailer: {} as MerchantContextType["retailer"],
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setMerchant: (merchant) => {},
});

const builtBestDiscount = (intl: IntlShape, deals: SearchDealsResponse) => {
  const bestDeal = deals?.deals?.find(
    (deal) => deal.promotions?.[0].type !== PROMOTION_TYPE_V2.OTHERS,
  );
  const promotion = bestDeal?.promotions?.[0];
  if (promotion) {
    if (
      promotion.type === PROMOTION_TYPE_V2.PERCENTAGE ||
      promotion.type === PROMOTION_TYPE_V2.PERCENTAGE_RANGE
    ) {
      return intl.formatMessage(
        { id: "stores.PERCENT_OFF" },
        { s1: () => `${promotion.value}%` },
      );
    } else if (
      promotion.type === PROMOTION_TYPE_V2.FIXED_AMOUNT ||
      promotion.type === PROMOTION_TYPE_V2.FIXED_AMOUNT_RANGE
    ) {
      return intl.formatMessage(
        { id: "stores.FIXED_AMOUNT_OFF" },
        { s1: () => `$${promotion.value}` },
      );
    } else if (promotion.type === PROMOTION_TYPE_V2.MONEY_BACK) {
      return intl.formatMessage(
        { id: "stores.MONEY_BACK" },
        { s1: () => `$${promotion.value}` },
      );
    } else if (promotion.type === PROMOTION_TYPE_V2.EXCLUSIVE) {
      return intl.formatMessage({ id: "stores.EXCLUSIVE" });
    } else if (promotion.type === PROMOTION_TYPE_V2.FREE_SHIPPING) {
      return intl.formatMessage({ id: "stores.FREE_SHIPPING" });
    }
  }
  return "";
};

export const MerchantProvider: FC<
  PropsWithChildren<{
    value: MerchantContextType;
  }>
> = ({ children, value }) => {
  const intl = useIntl();
  value.bestDiscount = builtBestDiscount(intl, value.deals);
  const [merchant, setMerchant] = useState<MerchantContextType>(value);

  return (
    <MerchantContext.Provider value={{ merchant, setMerchant }}>
      {children}
    </MerchantContext.Provider>
  );
};

export const useMerchantContext = () => useContext(MerchantContext);
